import { ActionButton, DateComponent, FieldGroupComponent, Form, RadioComponent, RadioOption, SelectComponent, TextComponent, utils, Widget } from '@truenorthmortgage/olympus';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import TableHelper from '../../components/table-helper/table-helper.component';
import { TableSchema } from '../../models/schemas/table-schema';
import { SERVICES } from '../../services';
import { DealService } from '../../services/deal.service';
import { displayErrors } from '../../components/form-helper/form-helper.component'; 

function calculateLtv(
    inputId: string,
    transactionType: string,
    homeValue: string,
    mortgage: string,
    equity: string,
    equityPercent: string,
    setLtv: (s: string) => void,
    setEquity: (s: string) => void,
    setEquityPercent: (s: string) => void) {
    let calculatedEquity: number | null = null;
    if (transactionType === 'renewal' || transactionType === 'refinance') {
        calculatedEquity = parseFloat(homeValue) - parseFloat(mortgage);
    } else if (transactionType === 'purchase' || transactionType === 'pre_approval') {
        if (inputId === 'equity-percent') {
            const floatEquity = parseFloat(equityPercent);
            calculatedEquity = ((floatEquity / 100) * parseFloat(homeValue));
            if (!isNaN(calculatedEquity)) {
                setEquity(calculatedEquity.toFixed(2));
            } else {
                setEquity('');
            }
        } else {
            calculatedEquity = parseFloat(equity);
            const percentDown = (calculatedEquity / parseFloat(homeValue)) * 100;
            if (!isNaN(percentDown)) {
                setEquityPercent(percentDown.toFixed(2));
            } else {
                setEquityPercent('');
            }
        }
    }

    const loan = parseFloat(homeValue) - (calculatedEquity ?? 0);
    const ltv = ((loan / parseFloat(homeValue)) * 100);
    setLtv(isNaN(ltv) || ltv === 0 ? '' : ltv.toFixed(2));
}

function treatAsUtc(date: Date) {
    const utcDate = new Date(date);
    utcDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return utcDate;
}

function daysBetween(startDate: Date, endDate: Date) {
    const milliseconds_per_day = 24 * 60 * 60 * 1000;
    return Math.ceil((treatAsUtc(endDate).getTime() - treatAsUtc(startDate).getTime()) / milliseconds_per_day);
}

function calculateNumberOfDays(submissionDate: Date, actionDate: Date, setNumberOfDays: (s: string) => any) {
    return setNumberOfDays(daysBetween(submissionDate, actionDate).toString());
}

const visibleFieldsByTransactionType: Record<string, string[] | Record<string, string[]>> = {
    'purchase': {
        '0': [
            'purchase-price',
            'equity',
            'equity-percent',
            'ltv',
            'amortization',
            'action-date',
            'number-of-days',
            'beacon-score',
            'property-province',
            'city',
            'property-use',
            'submission-date',
            'terms',
            'first-time-home-buyer',
            'new-build'
        ],
        '1': [
            'action-date',
            'number-of-days',
            'submission-date',
            'terms'
        ]
    },
    'renewal': {
        '0': [
            'purchase-price',
            'mortgage',
            'ltv',
            'amortization',
            'action-date',
            'number-of-days',
            'beacon-score',
            'already-insured',
            'property-province',
            'city',
            'property-use',
            'submission-date',
            'terms'
        ],
        '1': [
            'action-date',
            'number-of-days',
            'submission-date',
            'terms'
        ]
    },
    'refinance': [
        'purchase-price',
        'mortgage',
        'ltv',
        'amortization',
        'action-date',
        'number-of-days',
        'beacon-score',
        'property-province',
        'city',
        'property-use',
        'submission-date',
        'terms'
    ]
};

const Search = () => {
    const intl = useIntl();
    const dealService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const dispatch = useDispatch();
    const [results, setResults] = useState<TableSchema | undefined>();

    const [inputId, setInputId] = useState('');
    const [transactionType, setTransactionType] = useState('purchase');
    const [wizardMode, setWizardMode] = useState<string | null>('0');
    const [homeValue, setHomeValue] = useState<string | null>('');
    const [equity, setEquity] = useState<string | null>('');
    const [equityPercent, setEquityPercent] = useState('');
    const [ltv, setLtv] = useState('');
    const [mortgage, setMortgage] = useState<string | null>('');
    const [amortization, setAmortization] = useState<string | null>('25');
    const [actionDate, setActionDate] = useState<Date | null>(new Date());
    const [numberOfDays, setNumberOfDays] = useState<string | null>('');
    const [beaconScore, setBeaconScore] = useState<string | null>('780');
    const [propertyProvince, setPropertyProvince] = useState<string | null>('available_in_alberta');
    const [city, setCity] = useState<string | null>('44');
    const [propertyUse, setPropertyUse] = useState<string | null>('');
    const [submissionDate, setSubmissionDate] = useState<Date | null>(new Date());
    const [term, setTerm] = useState<string | null>('type=Fixed&identifier=years&value=5');
    const [alreadyInsured, setAlreadyInsured] = useState<string | null>('');
    const [firstTimeHomeBuyer, setFirstTimeHomeBuyer] = useState<string | null>(null);
    const [newBuild, setNewBuild] = useState<string | null>(null);
    const [hasErrors, setHasErrors] = useState<string[]>([]);

    useEffect(() => {
        calculateLtv(inputId, transactionType,
            homeValue ?? '',
            mortgage ?? '',
            equity ?? '',
            equityPercent ?? '',
            setLtv,
            setEquity,
            setEquityPercent);
    }, [inputId, transactionType, homeValue, mortgage, equity, equityPercent]);

    // Added for now since the results returned from the backend is just html markup not a schema.
    // Once we redo the backend, we will come back and add a onClick listenter properly.
    const toggleClass = useCallback((event: any) => {
        if (!event?.target?.classList.contains('only-fifths')) {
            return;
        }

        if (event.target.checked) {
            event.target.closest('.think-term-formula-table')?.classList.remove('show-only-fifths');
        } else {
            event.target.closest('.think-term-formula-table')?.classList.add('show-only-fifths');
        }

    }, []);
    
    useEffect(() => {
        const container = document.querySelector('#results-container');
        container?.addEventListener('click', toggleClass);

        return () => {
            container?.removeEventListener('click', toggleClass);
        };
    }, [results, toggleClass]);

    const calculateLtvCallback = useCallback((id: string, s: string, setter: ((s: string) => void) | ((s: string | null) => void)) => {
        setInputId(id);
        setter(s ?? '');
    }, [transactionType,
        homeValue,
        mortgage,
        equity,
        equityPercent]);

    const calculateNumberOfDaysCallback = useCallback((d: Date | null, setter: (s: Date | null) => void) => {
        calculateNumberOfDays(submissionDate ?? new Date(), actionDate ?? new Date(), setNumberOfDays);
        if (d) {
            setter(d);
        }

        // eslint-disable-next-line
    }, [submissionDate?.getTime(), actionDate?.getTime(), setNumberOfDays]);

    const onSubmit = useCallback(() => {
        dealService.searchRates({
            high_ratio: wizardMode,
            transaction_type: transactionType,
            home_value: homeValue,
            equity: equity,
            equity_percent: equityPercent,
            ltv,
            mortgage,
            amortization,
            action_date: actionDate,
            number_of_days: numberOfDays,
            beacon_score: beaconScore,
            property_province: propertyProvince,
            city,
            property_use: propertyUse,
            submission_date: submissionDate,
            terms: term,
            first_time_home_buyer: firstTimeHomeBuyer,
            new_build: newBuild
        })
            .then(setResults)
            .catch(e => {
                displayErrors(e, setHasErrors, dispatch);
            });
    }, [dealService, transactionType, hasErrors, wizardMode, homeValue, equity, equityPercent, ltv, mortgage, amortization, actionDate,
        numberOfDays, beaconScore, propertyProvince, city, propertyUse, submissionDate, term, firstTimeHomeBuyer, newBuild]);

    const showInput = useCallback((name: string) => {
        let controls: string[] | null = null;
        if (transactionType.length > 0 && (wizardMode?.length ?? 0 > 0)) {
            if (transactionType === 'refinance') {
                controls = visibleFieldsByTransactionType['refinance'] as string[];
            } else {
                controls = (visibleFieldsByTransactionType[transactionType] as Record<string, string[]>)[wizardMode!];
            }
        }

        if (controls) {
            return controls.includes(name);
        }
        return false;
    }, [transactionType, wizardMode]);
    
    const purchasePriceCallback = useCallback((s: string | null) => calculateLtvCallback('purchase-price', s ?? '', setHomeValue), [calculateLtvCallback]);
    const equityCallback = useCallback((s: string | null) => calculateLtvCallback('equity', s ?? '', setEquity), [calculateLtvCallback]);
    const equityPercentCallback = useCallback((s: string | null) => calculateLtvCallback('equity-percent', s ?? '', setEquityPercent), [calculateLtvCallback]);
    const mortgageCallback = useCallback((s: string | null) => calculateLtvCallback('mortgage', s ?? '', setMortgage), [calculateLtvCallback]);
    const actionDateCallback = useCallback((d: Date | null) => calculateNumberOfDaysCallback(d, setActionDate), [calculateNumberOfDaysCallback]);
    const submissionDateCallback = useCallback((d: Date | null) => calculateNumberOfDaysCallback(d, setSubmissionDate), [calculateNumberOfDaysCallback]);

    return (
        <>
            <Widget>
                <Form>
                    <FieldGroupComponent columnStyle="boxy field-group vertical">
                        <SelectComponent
                            triggerOnLoad
                            label={intl.formatMessage({ id: 'What type of transaction?' })}
                            value={transactionType}
                            onChange={setTransactionType}
                            columnStyle="half">
                            <option value="purchase">
                                {intl.formatMessage({ id: 'Purchase' })}
                            </option>
                            <option value="renewal">
                                {intl.formatMessage({ id: 'Switch' })}
                            </option>
                            <option value="refinance">
                                {intl.formatMessage({ id: 'Refinance' })}
                            </option>
                        </SelectComponent>
                        {
                            transactionType !== 'refinance' ? (
                                <RadioComponent
                                    name="wizard-mode"
                                    value={wizardMode ?? ''}
                                    onChange={setWizardMode}
                                    label={intl.formatMessage({ id: 'Wizard Mode' })}
                                    columnStyle="half">
                                    <RadioOption label={intl.formatMessage({ id: 'Rate Wizard' })} value="0" />
                                    <RadioOption label={intl.formatMessage({ id: 'Insured (Quick View)' })} value="1" />
                                </RadioComponent>
                            ) : null
                        }

                    </FieldGroupComponent>
                    <FieldGroupComponent columnStyle="field-group">
                        {
                            showInput('purchase-price') ? (
                                <TextComponent
                                    error={hasErrors.includes('home_value')}
                                    onBlur={purchasePriceCallback}
                                    label={intl.formatMessage({ id: transactionType === 'purchase' ? 'Purchase Price' : 'Home Value'})}
                                    columnStyle="quarter" />
                            ) : null
                        }
                        {
                            showInput('equity') ? (
                                <TextComponent
                                    formData={equity ?? ''}
                                    onBlur={equityCallback}
                                    label={intl.formatMessage({ id: 'Down Payment (as $)' })}
                                    columnStyle="quarter" />
                            ) : null
                        }
                        {
                            showInput('equity-percent') ? (
                                <TextComponent
                                    label={intl.formatMessage({ id: 'Down Payment (as %)' })}
                                    onBlur={equityPercentCallback}
                                    formData={equityPercent}
                                    columnStyle="quarter" />
                            ) : null
                        }
                        {
                            showInput('mortgage') ? (
                                <TextComponent
                                    label={intl.formatMessage({ id: transactionType === 'renewal' ? 'Current Mortgage' : 'Requested Mortgage' })}
                                    onChange={mortgageCallback}
                                    formData={mortgage ?? ''}
                                    columnStyle="quarter" />
                            ) : null
                        }
                        {
                            showInput('ltv') ? (
                                <TextComponent
                                    error={hasErrors.includes('ltv')}
                                    readOnly
                                    label={intl.formatMessage({ id: 'LTV' })}
                                    formData={ltv}
                                    onChange={setLtv}
                                    columnStyle="quarter" />
                            ) : null
                        }
                        {
                            showInput('amortization') ? (
                                <TextComponent
                                    error={hasErrors.includes('amortization')}
                                    formData={amortization ?? ''}
                                    onChange={setAmortization}
                                    label={intl.formatMessage({ id: transactionType !== 'renewal' ? 'Amortization' : 'Remaining Amortization' })}
                                    columnStyle="quarter" />
                            ) : null
                        }
                        {
                            showInput('action-date') ? (
                                <DateComponent
                                    label={intl.formatMessage({ id: 'Possession Date' })}
                                    value={actionDate ?? new Date()}
                                    onChangeDate={actionDateCallback}
                                    columnStyle="quarter" />
                            ) : null
                        }
                        {
                            showInput('number-of-days') ? (
                                <TextComponent
                                    readOnly
                                    label={intl.formatMessage({ id: 'Number of Days' })}
                                    formData={numberOfDays ?? ''}
                                    onChange={setNumberOfDays}
                                    columnStyle="quarter" />
                            ) : null
                        }
                        {
                            showInput('beacon-score') ? (
                                <TextComponent
                                    label={intl.formatMessage({ id: 'Beacon Score' })}
                                    formData={beaconScore ?? ''}
                                    onChange={setBeaconScore}
                                    columnStyle="quarter" />
                            ) : null
                        }
                        {
                            showInput('first-time-home-buyer') ? (
                                <RadioComponent
                                    name="first-time-home-buyer"
                                    value={firstTimeHomeBuyer ?? 'false'}
                                    onChange={setFirstTimeHomeBuyer}
                                    label={intl.formatMessage({ id: 'First Time Home Buyer?' })}
                                    columnStyle="quarter boxy vertical">
                                    <RadioOption label={intl.formatMessage({ id: 'Yes' })} value="true" />
                                    <RadioOption label={intl.formatMessage({ id: 'No' })} value="false" />
                                </RadioComponent>
                            ) : null
                        }
                        {
                            showInput('new-build') ? (
                                <RadioComponent
                                    name="new-build"
                                    value={newBuild ?? 'false'}
                                    onChange={setNewBuild}
                                    label={intl.formatMessage({ id: 'New Build?' })}
                                    columnStyle="quarter boxy vertical">
                                    <RadioOption label={intl.formatMessage({ id: 'Yes' })} value="true" />
                                    <RadioOption label={intl.formatMessage({ id: 'No' })} value="false" />
                                </RadioComponent>
                            ) : null
                        }
                        {
                            showInput('already-insured') ? (
                                <RadioComponent
                                    name="already-insured"
                                    value={alreadyInsured ?? 'false'}
                                    onChange={setAlreadyInsured}
                                    label={intl.formatMessage({ id: 'Current Mortgage Insured?' })}
                                    columnStyle="quarter boxy vertical">
                                    <RadioOption label={intl.formatMessage({ id: 'Yes' })} value="true" />
                                    <RadioOption label={intl.formatMessage({ id: 'No' })} value="false" />
                                </RadioComponent>
                            ) : null
                        }
                    </FieldGroupComponent>
                    <FieldGroupComponent columnStyle="boxy field-group">
                        {
                            showInput('property-province') ? (
                                <SelectComponent
                                    triggerOnLoad
                                    label={intl.formatMessage({ id: 'Property Province' })}
                                    value={propertyProvince ?? ''}
                                    onChange={setPropertyProvince}
                                    columnStyle="third">
                                    <option value="available_in_alberta">
                                        {intl.formatMessage({ id: 'Alberta' })}
                                    </option>
                                    <option value="available_in_british_columbia">
                                        {intl.formatMessage({ id: 'British Columbia' })}
                                    </option>
                                    <option value="available_in_manitoba">
                                        {intl.formatMessage({ id: 'Manitoba' })}
                                    </option>
                                    <option value="available_in_new_brunswick">
                                        {intl.formatMessage({ id: 'New Brunswick' })}
                                    </option>
                                    <option value="available_in_newfoundland_and_labrador">
                                        {intl.formatMessage({ id: 'Newfoundland and Labrador' })}
                                    </option>
                                    <option value="available_in_northwest_territories">
                                        {intl.formatMessage({ id: 'Northwest Territories' })}
                                    </option>
                                    <option value="available_in_nova_scotia">
                                        {intl.formatMessage({ id: 'Nova Scotia' })}
                                    </option>
                                    <option value="available_in_nunavut">
                                        {intl.formatMessage({ id: 'Nunavut' })}
                                    </option>
                                    <option value="available_in_ontario">
                                        {intl.formatMessage({ id: 'Ontario' })}
                                    </option>
                                    <option value="available_in_prince_edward_island">
                                        {intl.formatMessage({ id: 'Prince Edward Island' })}
                                    </option>
                                    <option value="available_in_quebec">
                                        {intl.formatMessage({ id: 'Quebec' })}
                                    </option>
                                    <option value="available_in_saskatchewan">
                                        {intl.formatMessage({ id: 'Saskatchewan' })}
                                    </option>
                                    <option value="available_in_yukon">
                                        {intl.formatMessage({ id: 'Yukon' })}
                                    </option>
                                </SelectComponent>
                            ) : null
                        }
                        {
                            showInput('city') ? (
                                <SelectComponent
                                    triggerOnLoad
                                    label={intl.formatMessage({ id: 'City' })}
                                    value={city ?? ''}
                                    onChange={setCity}
                                    columnStyle="third">
                                    <option value="">
                                        {intl.formatMessage({ id: 'Other' })}
                                    </option>
                                    <option value="44">
                                        {intl.formatMessage({ id: 'Calgary' })}
                                    </option>
                                    <option value="105">
                                        {intl.formatMessage({ id: 'Vancouver' })}
                                    </option>
                                    <option value="151">
                                        {intl.formatMessage({ id: 'Hamilton' })}
                                    </option>
                                    <option value="164">
                                        {intl.formatMessage({ id: 'Ottawa' })}
                                    </option>
                                    <option value="181">
                                        {intl.formatMessage({ id: 'Toronto' })}
                                    </option>
                                </SelectComponent>
                            ) : null
                        }
                        {
                            showInput('property-use') ? (
                                <SelectComponent
                                    triggerOnLoad
                                    label={intl.formatMessage({ id: 'Property Use' })}
                                    value={propertyUse ?? ''}
                                    onChange={setPropertyUse}
                                    columnStyle="third">
                                    <option value="primary">
                                        {intl.formatMessage({ id: 'Primary' })}
                                    </option>
                                    <option value="secondary">
                                        {intl.formatMessage({ id: 'Secondary/Vacation' })}
                                    </option>
                                    <option value="rental">
                                        {intl.formatMessage({ id: 'Rental' })}
                                    </option>
                                </SelectComponent>
                            ) : null
                        }
                        {
                            showInput('submission-date') ? (
                                <DateComponent
                                    label={intl.formatMessage({ id: 'Submission Date' })}
                                    value={submissionDate ?? new Date()}
                                    onChangeDate={submissionDateCallback}
                                    columnStyle="third" />
                            ) : null
                        }
                        {
                            showInput('terms') ? (
                                <SelectComponent
                                    triggerOnLoad
                                    label={intl.formatMessage({ id: 'Term' })}
                                    value={term ?? ''}
                                    onChange={setTerm}
                                    columnStyle="third">
                                    <option value="type=Fixed&identifier=name&value=6 Month - Insured">
                                        {intl.formatMessage({ id: '6 Month - Insured' })}
                                    </option>
                                    <option value="type=Fixed&identifier=years&value=1">
                                        {intl.formatMessage({ id: '1 Year Fixed' })}
                                    </option>
                                    <option value="type=Fixed&identifier=years&value=2">
                                        {intl.formatMessage({ id: '2 Year Fixed' })}
                                    </option>
                                    <option value="type=Fixed&identifier=years&value=3">
                                        {intl.formatMessage({ id: '3 Year Fixed' })}
                                    </option>
                                    <option value="type=Fixed&identifier=years&value=5">
                                        {intl.formatMessage({ id: '5 Year Fixed' })}
                                    </option>
                                    <option value="type=Variable&identifier=years&value=5">
                                        {intl.formatMessage({ id: '5 Year Variable' })}
                                    </option>
                                </SelectComponent>
                            ) : null
                        }
                        <div className="column third buttons">
                            <ActionButton className="button primary form-trigger right full-width flex-end" callback={onSubmit}>
                                <FormattedMessage id="Search" />
                            </ActionButton>
                        </div>
                    </FieldGroupComponent>
                </Form>
            </Widget>
            <div id="results-container">
                {
                    results ? (
                        <div id="results">
                            <TableHelper schema={results} headerless={true} />
                        </div>
                    ) : null
                }
            </div>
        </>
    );
};

export default Search;
