import { CheckboxComponent, CheckboxOption, PasswordComponent, TextComponent, utils } from '@truenorthmortgage/olympus';
import { FC, FormEvent, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router';
import { ValidationError } from '../../errors/validation-error';
import logo from '../../resources/images/think-logo-stacked.svg';
import { SERVICES } from '../../services';
import { AuthService } from '../../services/auth.service';
import { LoginService } from '../../services/login-page.service';
import Login2fa from './login-2fa.component';

// eslint-disable-next-line
export type LoginPageProps = {};

const LoginPage: FC<LoginPageProps> = () => {
    const intl = useIntl();
    const location = useLocation();
    const dispatch = useDispatch();
    const [state, setState] = useState<Record<string, string>>({});
    const [loggedIn, setLoggedIn] = useState(false);
    const [errors, setErrors] = useState<string[]>();
    const [callbacks, setCallbacks] = useState<Record<string, (value: any) => void>>({});
    const [login2fa, setLogin2fa] = useState<boolean>(false);
    const [options, setOptions] = useState<Record<string, any>>({});
    const [token, setToken] = useState<string>('');

    const loginService = utils.injection.useInjection<LoginService>(SERVICES.LoginService);
    const authService = utils.injection.useInjection<AuthService>(SERVICES.AuthService);
    const onChange = useCallback((fieldName: string) => {
        if (callbacks[fieldName]) {
            return callbacks[fieldName];
        }
        const callback = (value: any) => setState(state => Object.assign({}, state, { [fieldName]: value ?? '0' }));
        callbacks[fieldName] = callback;
        setCallbacks(callbacks);
        return callback;
    }, [callbacks, setCallbacks, setState]);

    const onSubmit = useCallback((event: FormEvent) => {
        event.preventDefault();
        loginService.getToken({ LoginForm: state })
            .then((data) => {
                if (!data.login2fa) {
                    authService.logIn(data.jwt, dispatch);
                    setLoggedIn(true);
                } else {
                    authService.getLogin2faOptions(data.jwt).then(setOptions);
                    setLogin2fa(data.login2fa);
                    setToken(data.jwt);
                }
            })
            .catch(e => {
                if (e instanceof ValidationError) {
                    setErrors(e.errors.password);
                }
            });
    }, [state, login2fa, options, loginService, location, authService, dispatch]);

    const cancelLogin2fa = useCallback(() => {
        setLogin2fa(false);
    }, [login2fa]);

    return !loggedIn ? !login2fa ? (
        <div id="login-page">
            <form id="login-form" method="post" onSubmit={onSubmit}>
                <div className="columns">
                    <div className="column">
                        <img src={logo} alt="Logo" className="img-responsive" />
                    </div>
                    {errors ? errors.map((error, index) => (
                        <p className="column" key={index}>{error}</p>
                    )) : null}
                    <TextComponent
                        name="email"
                        placeholder={intl.formatMessage({ id: 'Email' })}
                        onChange={onChange('email')}
                        formData={state['email'] ?? ''} />

                    <PasswordComponent
                        name="password"
                        placeholder={intl.formatMessage({ id: 'Password' })}
                        onChange={onChange('password')}
                        value={state['password'] ?? ''} />

                    <CheckboxComponent name="rememberMe" onChange={onChange('keepLogin')} value={state['keepLogin']}>
                        <CheckboxOption label={intl.formatMessage({ id: 'Keep me Logged in' })} value={'1'}/>
                    </CheckboxComponent>

                    <div className="column">
                        <div className="buttons">
                            <button type="submit" className="button primary full-width">
                                <FormattedMessage id="Login" />
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    ) : <Login2fa options={options} token={token} onLogout={cancelLogin2fa} /> : (<Navigate to="/" />);
};

export default LoginPage;
